import { MouseEventHandler } from 'react';
import { Map } from '../icons';
import './MapLink.scss';

interface MapLinkProps {
  mapLink: string;
}
function MapLink({ mapLink }: MapLinkProps): JSX.Element {
  const _handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    window.open(mapLink, '_blank');
  }

  return (
    <div className='map-link' onClick={_handleClick}>
      <Map />        
    </div>
  )
}

export default MapLink;
