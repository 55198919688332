import { useMemo } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import BottomNav, { NavLocation } from 'components/molecules/BottomNav';
import { routes  } from 'pages/routes';
import './HomePage.scss';

function HomePage() {
  const navigate = useNavigate(); 
  const location = useLocation();

  const currentLotion = useMemo<NavLocation>(() => {
    if (location.pathname === routes.PLACES.full) {
      return NavLocation.Places
    }
    return NavLocation.Info;
  }, [location])

  function navToInfo() {
    navigate(routes.INFO.full);
  }

  function navToPlaces() {
    navigate(routes.PLACES.full);
  }

  return (
    <div className='home-page'>
      <Outlet />
      <div className='nav-bar'>
        <BottomNav
          currentLocation={currentLotion}
          navToInfo={navToInfo}
          navToPlaces={navToPlaces}
        />
      </div>
    </div>
  )
}

export default HomePage;
