import { ReactNode, useMemo } from 'react';
import { Money, Maintenance } from '../icons';
import { ListItemType } from './types';
import './ListItem.scss';

interface Listitemprops {
  type: ListItemType;
  content: string;
  dueDate: number;
}
function ListItem({ type, content, dueDate }: Listitemprops) {
  const Icon = useMemo<ReactNode>(() => {
    if (type === ListItemType.Bill) {
      return <Money />;
    }
    if (type === ListItemType.Maintenance) {
      return <Maintenance />;
    }
    return <div />;
  }, [type]);

  const formattedDate = useMemo<string>(() => {
    const d = new Date(dueDate);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  }, [dueDate]);

  const dueDateClass = useMemo<string>(() => {
    if (Date.now() > dueDate) {
      return 'due-date overdue';
    }
    return 'due-date';
  }, [dueDate]);

  return (
    <div className='list-item'>
      <div className='icon'>
        {Icon}
      </div>
      <div className='content'>
        {content}
      </div>
      <div className={dueDateClass}>
        {formattedDate}
      </div>
    </div>
  )
}

export default ListItem;
