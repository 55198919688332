import ListItem from 'components/atoms/ListItem';
import { Jobs } from 'modules/Jobs';
import './JobsList.scss';

interface JobsListProps {
  jobs: Jobs;
}
function JobsList({ jobs }: JobsListProps) {
  return (
    <div className='jobs-list'>
      <div className='title'>Jobs</div>
      {jobs.map((job, i) => <ListItem key={`job-${i}`} {...job} />)}
    </div>
  )
}

export default JobsList;
