import { CSSProperties, ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash'
import { useLocations, LocationCategory } from 'modules/Locations';
import { Filter, Close } from 'components/atoms/icons';
import './LocationFilter.scss';

function LocationFilter(): JSX.Element {
  const { filters, setFilters } = useLocations();

  const [open, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<{
    category: keyof typeof LocationCategory;
    checked: boolean;
  }[]>([]);

  const containerClasses = useMemo<string>(() => {
    if (open) {
      return 'open expand';
    }
    return '';
  }, [open]);

  const openClass = useMemo<string>(() => {
    if (open) {
      return 'open';
    }
    return '';
  }, [open]);

  const filterStyle = useMemo<CSSProperties>(() => {
    if (open) {
      return {
        display: 'none'
      }
    }
    return {};
  }, [open]);

  const categoryStyle = useMemo<CSSProperties>(() => {
    if (open) {
      return {};
    }
    return {
      display: 'none'
    }
  }, [open]);

  const _resetFilters = useCallback(() => {
    setChecked(Object.values(LocationCategory).map(cat => {
      return {
        category: cat,
        checked: filters.includes(cat)
      }
    }))
  }, [filters]);

  function _handleOpen() {
    setOpen(isOpen => {
      if (isOpen) {
        document.body.style.position = '';
        _resetFilters();
        return false;
      }
      document.body.style.position = 'fixed';
      return true;
    });
  }

  function _clearFilters() {
    setChecked(prev => {
      return prev.map(({ category }) => {
        return {
          category,
          checked: false
        }
      });
    });
  }

  const _handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChecked(prev => {
      return prev.map(c => {
        if (c.category === e.target.name) {
          return {
            category: c.category,
            checked: !c.checked
          }
        };
        return {
          ...c
        };
      });
    })
  };

  function _applyFilters() {
    const newFilters = checked.filter(({ checked }) => checked)
      .map(({ category }) => category) as LocationCategory[];
    _handleOpen(); 
    if (isEqual(newFilters, filters)) {
      return;
    }
    setFilters(newFilters);
  }

  useEffect(() => {
    _resetFilters();
  }, [filters, _resetFilters]);

  return (
    <div className={`location-filter-container ${containerClasses}`}>
      <div className={`location-filter ${openClass}`} onClick={_handleOpen}>
        <div className={`location-filter--icon ${openClass}`}>
          {open ? <Close /> : <Filter />}
        </div>
        <div
          style={filterStyle}
          className={`location-filter--text ${openClass}`}
        >
          Filters
        </div>
      </div>
      <div className='categories' style={categoryStyle}>
        {checked.map(({ category, checked }, i) => (
          <div className='category' key={`${category}-${i}`}>
            <input
              type='checkbox'
              name={category}
              checked={checked}
              onChange={_handleChange}
            />
            <label htmlFor={category}>{category}</label>
          </div>
        ))}
      </div>
      <div className='buttons' style={categoryStyle}>
        <div
          role="button"
          className='apply-button'
          onClick={_applyFilters}
        >
          Apply Filters
        </div>
        <div
          role="button"
          className='apply-button reset'
          onClick={_clearFilters}
        >
          Clear Filters
        </div>
      </div>
    </div>
  );
}

export default LocationFilter;
