import { createContext } from 'react';
import { Location, LocationCategory } from './types';

export interface ILocationContext {
  loading: boolean;
  locations: Location[];
  filters: LocationCategory[];
  setFilters: (categories: LocationCategory[]) => void;
}

export const LocationContext = createContext<ILocationContext>({
  loading: false,
  locations: [],
  filters: [],
  setFilters: (categories: LocationCategory[]) => {},
});
