
function Link(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M5.636 5.636a4 4 0 015.657 0l2.828 2.828a4 4 0 010 5.657 1 1 0 11-1.414-1.414 2 2 0 000-2.828L9.88 7.05A2 2 0 007.05 9.88l.707.707A1 1 0 116.343 12l-.707-.707a4 4 0 010-5.657zm5.657 4.243a1 1 0 010 1.414 2 2 0 000 2.828l2.828 2.829a2 2 0 002.829-2.829l-.707-.707A1 1 0 1117.657 12l.707.707a4 4 0 01-5.657 5.657L9.88 15.536a4 4 0 010-5.657 1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Link;
