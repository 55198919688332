import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LocationContext } from './contexts';
import { Location, LocationCategory } from './types';
import { getLocations } from './helpers';

export function LocationProvider(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [filters, setFilters] = useState<LocationCategory[]>([]);

  const refresh = useCallback(async () => {
    setLoading(false);
    const updatedLocations = await getLocations(filters);
    setLocations(updatedLocations);
    setLoading(false);
  }, [filters]);
  
  useEffect(() => {
    refresh();    
  }, [filters, refresh]);

  return (
    <LocationContext.Provider
      value={{
        loading,
        locations,
        filters,
        setFilters,
      }}
    >
      <Outlet />
    </LocationContext.Provider>
  )
};
