import InfoDialog from "components/atoms/InfoDialog";
import { useJobs } from "modules/Jobs";
import JobsList from "components/molecules/JobsList";
import "./InfoPage.scss";

function InfoPage() {
  const { jobs } = useJobs();

  return (
    <div className="info-page">
      <div className="dialog">
        <InfoDialog>
          <div className="title">Bertie is now awake</div>
          <div className="text">And is looking forward to a new season</div>
        </InfoDialog>
      </div>
      <div className="jobs">
        <JobsList jobs={jobs} />
      </div>
    </div>
  );
}

export default InfoPage;
