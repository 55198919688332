import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BertieIcon from 'components/atoms/BertieIcon';
import { routes } from 'pages/routes';
import './LandingPage.scss';

function LandingPage(): JSX.Element {
  const navigate = useNavigate();

  const [fading, setFading] = useState<boolean>(false);

  const headerClass = useMemo<string>(() => {
    if (fading) {
      return 'header fade-out'
    }
    return 'header fade-in'
  }, [fading])

  const iconClass = useMemo<string>(() => {
    if (fading) {
      return 'icon fade-out';
    }
    return 'icon fade-in';
  }, [fading]);

  const subHeaderClass = useMemo<string>(() => {
    if (fading) {
      return 'sub-header fade-out'
    }
    return 'sub-header fade-in-450';
  }, [fading])

  const enterButtonClass = useMemo<string>(() => {
    if (fading) {
      return 'enter-button fade-out';
    }
    return 'enter-button fade-in-650';
  }, [fading])

  function _handleEnter() {
    setFading(true);
    setTimeout(() => {
      navigate(routes.INFO.full);
    }, 250);
  }

  return (
    <div className='landing-page'>
      <div className='landing-content'>
        <div className='image'>
          <img src='/ogden.jpg' alt="canal" />
        </div>
        <div className='overlay' />
        <div className={headerClass}>
          Welcome
        </div>
        <div className={iconClass}>
          <BertieIcon size='fill' />
        </div>
        <div className={subHeaderClass}>
          To Bertie
        </div>
        <div role='button' className={enterButtonClass} onClick={_handleEnter}>
          Enter
        </div>
      </div>
    </div>
  )
}

export default LandingPage;
