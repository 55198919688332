import { CSSProperties, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import MapLink from 'components/atoms/MapLink';
import WebLink from 'components/atoms/WebLink/WebLink';
import ImageCarousel from '../ImageCarousel';
import type { Location } from 'modules/Locations';
import './LocationCard.scss';

interface LocationCardProps extends Location {}
function LocationCard({
  imgSrc,
  header,
  category,
  description,
  mapLink,
  webLink
}: LocationCardProps): JSX.Element {
  const locationCard = useRef<HTMLDivElement>(null);

  const [showDescription, setShowDescription] = useState<boolean>(false);

  const webLinkStyle = useMemo<CSSProperties>(() => {
    if (showDescription) {
      return { display: 'none' };
    }
    if (webLink) {
      return {};
    }
    return { display: 'none' };
  }, [webLink, showDescription]);

  const mapLinkStyle = useMemo<CSSProperties>(() => {
    if (showDescription) {
      return { display: 'none' };
    }
    return {};
  }, [showDescription]);

  const detailsStyle = useMemo<CSSProperties>(() => {
    if (showDescription) {
      return { display: 'none' };
    }
    return {};
  }, [showDescription]);

  const descriptionStyle = useMemo<CSSProperties>(() => {
    if (showDescription) {
      return {};
    }
    return { display: 'none' };
  }, [showDescription]);

  const _handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    setShowDescription(show => !show);
  };

  function _handleClickOff(e: MouseEvent): void {
    if (locationCard.current && !locationCard.current.contains(e.target as Node)) {
      setShowDescription(false);
    } 
  };

  useEffect(() => {
    document.addEventListener('click', _handleClickOff);

    return () => {
      document.removeEventListener('click', _handleClickOff);
    }
  }, []);

  return (
    <div
      ref={locationCard}
      className='location-card-container'
      onClick={_handleClick}
    >
      <div className='location-card-container--picture'>
        <ImageCarousel images={imgSrc} hideNav={showDescription} />
      </div>
      <div style={mapLinkStyle} className='location-card-container--map-link'>
        <MapLink mapLink={mapLink} />
      </div>
      <div style={webLinkStyle} className='location-card-container--web-link'>
        <WebLink webLink={webLink as string} />
      </div>
      <div style={detailsStyle} className='location-card-container--details'>
        <div className='location-card-container--details--header'>
          {header}
        </div>
        <div className='location-card-container--details--sub'>
          {category}
        </div>
      </div>
      <div style={descriptionStyle} className='location-card-container--description'>
        <div className='location-card-container--description--header'>
          {header}
        </div> 
        <div className='location-card-container--description--content'>
          {description}
        </div> 
      </div>
    </div>
  );
}

export default LocationCard;
