export enum LocationCategory {
  Walks = 'Walks',
  Villages = 'Villages',
  Towns = 'Towns',
  Pubs = 'Pubs',
  Shops = 'Shops',
  Cafes = 'Cafes',
  Restaurants = 'Restaurants'
}

export interface Location {
  imgSrc: string[];
  header: string;
  category: LocationCategory;
  description: string;
  mapLink: string;
  webLink?: string;
}
