type Routes = Record<string, { path: string; full: string;}>;
export const routes: Routes = {
  LANDING: {
    path: '',
    full: '/'
  },
  HOME: {
    path: 'home',
    full: '/home'
  },
  get INFO() {
    return {
      path: 'info',
      full: `${this.HOME.full}/info`
    }
  },
  get PLACES() {
    return {
      path: 'places',
      full: `${this.HOME.full}/places`
    }
  },
};
