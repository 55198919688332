import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { JobsContext } from './contexts';
import { getJobs } from './helpters';
import { Jobs } from './types';

export function JobsProvider() {
  const [jobs, setJobs] = useState<Jobs>([]);

  useEffect(() => {
    setJobs(getJobs());
  }, []);

  return (
    <JobsContext.Provider value={{ jobs }}>
      <Outlet />
    </JobsContext.Provider>
  )
}
