import { MouseEventHandler } from 'react';
import { Link } from '../icons';
import './WebLink.scss';

interface WebLinkProps {
  webLink: string;
}
function WebLink({ webLink }: WebLinkProps): JSX.Element {
  const _handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    window.open(webLink, '_blank');
  }

  return (
    <div className='web-link' onClick={_handleClick}>
      <Link />        
    </div>
  )
}

export default WebLink;

