import { Jobs } from "./types";
import { ListItemType } from "components/atoms/ListItem";

export const jobs: Jobs = [
  {
    type: ListItemType.Bill,
    content: "Buy Air Frier",
    dueDate: 1711926000000,
  },
  {
    type: ListItemType.Maintenance,
    content: "Boiler service",
    dueDate: 1714518000000,
  },
];
