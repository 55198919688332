import { useRef, useState, useMemo, MouseEventHandler } from 'react';
import './ImageCarousel.scss';

interface ImageCarouselProps {
  images: string[];
  hideNav: boolean;
}
function ImageCarousel({ images, hideNav }: ImageCarouselProps) {
  const carousel = useRef<HTMLDivElement>(null);

  const [imageIndex, setImageIndex] = useState<number>(0);

  const showLeftNav = useMemo<boolean>(() => {
    if (hideNav) {
      return false;
    }
    if (imageIndex === 0) {
      return false;
    }
    return true;
  }, [hideNav, imageIndex])

  const showRightNav = useMemo<boolean>(() => {
    if (hideNav) {
      return false;
    }
    if (imageIndex === images.length - 1) {
      return false;
    }
    return true;
  }, [hideNav, imageIndex, images])

  const _navLeft: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!showLeftNav) {
      return;
    }
    e.stopPropagation();

    setImageIndex(i =>{
      const newIndex = i - 1;
      if (carousel.current) {
        const width = carousel.current.clientWidth;
        carousel.current.scrollTo({
          left: newIndex * width,
          behavior: 'smooth'
        })
      }
      return newIndex;
    });
  }

  const _navRight: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!showRightNav) {
      return;
    }
    e.stopPropagation();

    setImageIndex(i => {
      const newIndex = i + 1;
      if (carousel.current) {
        const width = carousel.current.clientWidth;
        carousel.current.scrollTo({
          left: newIndex * width,
          behavior: 'smooth'
        })
      }
      return newIndex;
    });
  }

  return (
    <div className='carousel-container' id='container'>
      <div ref={carousel} className='carousel' id="carousel">
        {images.map((image, index) => (
          <div key={`${index}-${image}`} className='carousel-item'>
            <img src={image} alt='' />
          </div>
        ))}
      </div>
      {showLeftNav && (
        <div className='nav-left' onClick={_navLeft}>
          <div className='arrow left' />
        </div>
      )}
      {showRightNav && (
        <div className='nav-right' onClick={_navRight}>
          <div className='arrow right' />
        </div>
      )}
    </div>
  )
}

export default ImageCarousel;
