import { createContext } from 'react';
import { Jobs } from './types';

export interface IJobsContext {
  jobs: Jobs;
}

export const JobsContext = createContext<IJobsContext>({
  jobs: []
});
