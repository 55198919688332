import { ReactNode } from 'react';
import { Info } from 'components/atoms/icons';
import './InfoDialog.scss';

interface InfoDialogProps {
  children: ReactNode;
}
function InfoDialog({ children }: InfoDialogProps) {
  return (
    <div className='dialog-container'>
      <div className='icon'>
        <Info />
      </div>
      <div className='content'>
        {children}
      </div>
    </div>
  )
}

export default InfoDialog;
