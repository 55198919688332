import LocationFilter from 'components/molecules/LocationFilter';
import LocationCard from 'components/molecules/LocationCard';
import LoadingModal from 'components/molecules/LoadingModal';
import { useLocations } from 'modules/Locations';
import './LocationCardList.scss';

function LocationCardList(): JSX.Element {
  const { locations, loading } = useLocations();

  return (
    <>
      <div className='location-filters'>
        <LocationFilter />
      </div>
      <div className='location-card-list'>
        {locations.map((location, index) => 
          <LocationCard key={`location-${index}`} {...location} />
        )}
      </div>
      {loading && <LoadingModal />}
    </>
  )
}

export default LocationCardList;
