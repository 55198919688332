import React from "react";

function Maintenance() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" transform="translate(42.667 42.667)">
          <path d="M213.333 0C330.96 0 426.667 95.707 426.667 213.333c0 117.627-95.707 213.334-213.334 213.334C95.707 426.667 0 330.96 0 213.333 0 95.707 95.707 0 213.333 0zm0 42.667c-94.44 0-170.666 76.226-170.666 170.666S118.893 384 213.333 384 384 307.774 384 213.333c0-94.44-76.226-170.666-170.667-170.666zm.914 72.781c16.137 20.983 21.581 46.796 16.41 65.641-.645 3.101 22.099 27.283 68.232 72.545 15.085 15.085 15.085 30.17 0 45.255-14.198 14.197-28.395 15.032-42.593 2.505l-74.87-71.074c-18.846 5.171-44.658-.273-65.64-16.41-15.39-20.235-20.697-46.685-16.41-65.641l32.82 32.82 32.82-16.41 16.41-32.82-32.82-32.821c18.957-4.287 45.407 1.02 65.641 16.41z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Maintenance;
