import { useMemo, ReactNode } from 'react';
import './NavTile.scss';

interface NavTileProps {
  selected: boolean;
  onClick(): void;
  children: ReactNode;
}
function NavTile({ selected, onClick, children }: NavTileProps) {
  const navClass = useMemo<string>(() => {
    if (selected) {
      return 'info-nav selected';
    }
    return 'info-nav';
  }, [selected]);

  return (
    <div className={navClass} onClick={onClick}>
      <div className='icon'>
        {children}
      </div>
    </div>
  )
}

export default NavTile;
