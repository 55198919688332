import { Location, LocationCategory } from "./types";

export async function getLocations(
  filters: LocationCategory[],
): Promise<Location[]> {
  const response = await fetch("/api/locations");
  const serverLocations = await response.json();
  return new Promise((reseolve) => {
    if (filters.length) {
      reseolve(
        serverLocations.filter((l: Location) => filters.includes(l.category)),
      );
      return;
    }
    reseolve(serverLocations);
  });
}
