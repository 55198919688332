import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import HomePage from './HomePage';
import { JobsProvider } from 'modules/Jobs';
import InfoPage from './InfoPage';
import { LocationProvider } from 'modules/Locations';
import LocationCardList from './LocationCardList';
import { routes } from './routes';

function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.LANDING.full} element={<LandingPage />} />
        <Route path={routes.HOME.full} element={<HomePage />}>
          <Route path={routes.INFO.path} element={<JobsProvider />}>
            <Route path='' element={<InfoPage />} />
          </Route>
          <Route path={routes.PLACES.path} element={<LocationProvider />}>
            <Route path='' element={<LocationCardList />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router;
