import BertieIcon from 'components/atoms/BertieIcon';
import './LoadingModal.scss';

function LoadingModal(): JSX.Element {
  return (
    <div className='loading-modal'>
      <div className='loading-modal--icon'>
        <BertieIcon size='medium' />
      </div>
    </div>
  )
}

export default LoadingModal;
