import React from "react";

function Money() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 8.5l-.11-.164A3 3 0 0012.395 7H12a3 3 0 00-3 3v3.757a3 3 0 01-.879 2.122L8 16h7m-7.5-4H12m9 0a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
  );
}

export default Money;
