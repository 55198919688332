import './BertieIcon.scss';

interface BertieIconProps {
  size: 'small' | 'medium' | 'large' | 'fill';
}
function BertieIcon({ size }: BertieIconProps): JSX.Element {
  return (
    <div className={`bertie-icon--${size}`}>
      <div className={`bertie-icon--${size}--hill`} />
    </div>
  );
}

export default BertieIcon;
