import { useMemo } from 'react';
import NavTile from 'components/atoms/NavTile';
import { Info, Map } from 'components/atoms/icons';
import { NavLocation } from './types';
import './BottomNav.scss';

interface BottomNavProps {
  navToInfo(): void;
  navToPlaces(): void;
  currentLocation: NavLocation;
}
function BottomNav({ navToInfo, navToPlaces, currentLocation }: BottomNavProps) {
  const infoSelected = useMemo<boolean>(() => {
    if (currentLocation === NavLocation.Info) {
      return true;
    }
    return false;
  }, [currentLocation]);

  const placesSelected = useMemo<boolean>(() => {
    if (currentLocation === NavLocation.Places) {
      return true;
    }
    return false;
  }, [currentLocation]);

  return (
    <div className='bottom-nav'>
      <div className='nav-element'>
        <NavTile selected={infoSelected} onClick={navToInfo}>
          <Info />
        </NavTile>
      </div>
      <div className='nav-element'>
        <NavTile selected={placesSelected} onClick={navToPlaces}>
          <Map />
        </NavTile>
      </div>
    </div>
  );
}

export default BottomNav;
